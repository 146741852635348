import React from "react"
import { Link } from "gatsby"
import Seo from "../components/Seo"

const Error = () => {
  return (
    <>
      <h2>error page</h2>
    </>
  )
}

export default Error
